import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import coloredHouses from '../../../images/blocks/colored_houses.png';
import wc from '../../../images/blocks/wc.png';
import hospital from '../../../images/blocks/hospital.png';
import warHousesAir from '../../../images/blocks/war_houses_air.png';
import schoolInside from '../../../images/blocks/school_inside.png';
import tradeOutside from '../../../images/blocks/trade_outside.png';
import officeInside1 from '../../../images/blocks/office_inside_1.png';
import './blocks.css';


class Blocks extends Component {
    render() {
        return (
                <section className="our-blocks  websitePadding pt-40 pb-40" id="blocks">
                    <h2 className="text-center yellow">Модульні будівлі / Приміщення для ВПО</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content ">
                            <h3 className="text-center">Індивідуальні, гнучкі та універсальні</h3>
                            <ul>
                                <li><h4>Можна пристосувати чи розширити у будь-який час</h4></li>
                                <li><h4>Індивідуальне планування приміщень</h4></li>
                                <li><h4>Модульні будинки до 3 поверхів</h4></li>
                                <li><h4>Оптимальна тепло- та звукоізоляція</h4></li>
                                <li><h4>Різні розміри (від 10' до 30')</h4></li>
                                <li><h4>Різні варіанти оформлення та оснащення</h4></li>
                                <li><h4>Модульне містечко під ключ за декілька тижнів</h4></li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={coloredHouses} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Сантехнічні модулі та модулі-душові</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={wc} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <h3 className="text-center">Чисто та без шкоди для навколишнього середовища</h3>
                            <ul>
                                <li><h4>Повна готовність до експлуатації</h4></li>
                                <li><h4>Оптимальна тепло- та звукоізоляція</h4></li>
                                <li><h4>Комплектне та комфортне оснащення</h4></li>
                                <li><h4>Чиста вода замість хімічних засобів</h4></li>
                                <li><h4>Різні розміри (від 10' до 30')</h4></li>
                                <li><h4>Різні варіанти оформлення та оснащення</h4></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Мобільні шпиталі</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <h3 className="text-center">Розгортання медичної інфраструктури за короткий термін, яка гарантуватиме безпеку пацієнтів та медперсоналу</h3>
                            <ul>
                                <li><h4>Швидкість розгортання</h4></li>
                                <li><h4>Можливість релокації в будь-який момент</h4></li>
                                <li><h4>Мінімальні потреби до місця розгортання шпиталя</h4></li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={hospital} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Модулі для силових та військових структур</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={warHousesAir} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <h3 className="text-center">Там, де якість і мобільність мають значення, потрібні найбільш якісні модульні системи</h3>
                            <ul>
                                <li><h4>Проєктуємо військові модулі на основі висновків і рекомендацій щодо вимог і тенденцій розвитку військової інфраструктури</h4></li>
                                <li><h4>Наша продукція має всі необхідні ліцензії та сертифікати для впровадження спеціалізованих рішень, що відповідатимуть очікуванням</h4></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Приміщення для соціальної інфраструктури (дитячі садочки / школи / приміщення ВНЗ) та інше</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <ul>
                                <li><h4>Швидкість разгортання соціальновагомих об'єктів</h4></li>
                                <li><h4>Тимчасові або постійні рішення для кімнат</h4></li>
                                <li><h4>Можливість пристосувати або розширити в будь-який час</h4></li>
                                <li><h4>Індивідуальні варіанти оформлення й устаткування</h4></li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={schoolInside} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Приміщення для торгівлі / організації демонстраційних залів</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={tradeOutside} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <ul>
                                <li><h4>Сучасні вікна</h4></li>
                                <li><h4>Індивідуальний зовнішній вигляд</h4></li>
                                <li><h4>Різноманітні варіанти внутрішнього оформлення й устаткування</h4></li>
                            </ul>
                        </Grid>
                    </Grid>
                    <h2 className="text-center yellow">Офісні приміщення та центри надання послуг</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                            <h3 className="text-center">Сучасний і функціональний простір для сучасного офісу</h3>
                            <ul>
                                <li><h4>Індивідуальні варіанти оформлення й устаткування</h4></li>
                                <li><h4>Надшвидкі темпи зведення</h4></li>
                                <li><h4>Зручні офісні й побутові приміщення</h4></li>
                                <li><h4>Оптимальне кондиціонування повітря й теплоізоляція</h4></li>
                                <li><h4>Можливість релокації в будь-який момент</h4></li>
                            </ul>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} className="video-section-content img-container">
                            <Grid className="single-block">
                                <Grid>
                                    <div>
                                        <img src={officeInside1} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        )
    }
}

export default Blocks;