import React, {Component} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import Header from '../Home1/Header';
import HeroSlider from '../Home1/HeroSlider';
import WhatWeDo from '../Home1/WhatWeDo';
import About from "../About";
import List from "./List";
import Products from "./Products";
import Panels from "./Panels";
import Blocks from "./Blocks";
import Using from "./Using";
import Companies from "./Companies";

class HomePage extends Component{
    render() {
        return(
            <div>
                <Header/>
                <HeroSlider/>
                <About/>
                <WhatWeDo/>
                <List/>
                <Products/>
                <Panels/>
                <Blocks/>
                <Using/>
                <Companies/>
            </div>
        )
    }

}
export default HomePage;