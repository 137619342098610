import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Brand from '../../images/brand_yellow.png';
import IconWe from '../../images/icons/we.png';
import IconBus from '../../images/icons/bus.png';
import IconHouse from '../../images/icons/house.png';
import IconPeople from '../../images/icons/people.png';
import IconOk from '../../images/icons/ok.png';
import IconTech from '../../images/icons/tech.png';
import IconWar from '../../images/icons/war.png';

class About extends Component {
    render() {
        return (
            <section id="about" className="our-services pt-120 pb-80">
                <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}></Grid>
                    <Grid item lg={10} xs={12} className="offset-lg-1 col-12 text-center">
                        <Grid className="what-we-do-title">
                            <span>
                                <img src={Brand} alt="" width="20%"/>
                            </span>
                            <h2>приватна українська компанія, яка входить до групи компаній, що спеціалізуються
на будівництві, поставках будівельних матеріалів та <span>транспортних послугах.</span>
                            </h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className="websitePadding">
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconWe} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Заснована в 2006 році</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconBus} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Власний парк спеціальної техніки для транспортування та монтажу</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconHouse} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Зручні склади для тимчасового зберігання та демонстраційних потреб по Україні</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconPeople} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Сучасна команда спеціалістів</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconOk} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Відмінна репутація та рекомендації будівельних компаній</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconTech} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Досвід найкращих європейских компаній, реалізований в Україні</h3>
                        </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12} className="">
                        <Grid className="single-service">
                            <Grid className="service-icon">
                                <Grid className="service-shape-icon_">
                                    <img src={IconWar} alt=""/>
                                </Grid>
                            </Grid>
                            <h3>Частина прибутку передається на потреби ЗСУ</h3>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default About;