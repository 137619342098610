import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Slider from "react-slick";
import publicEvent1 from '../../../images/using/public_event_2.png';
import publicEvent2 from '../../../images/using/public_event_1.png';
import business from '../../../images/using/business.png';
import construction from '../../../images/using/construction.png';
import freeRooms1 from '../../../images/using/free_rooms_1.png';
import freeRooms2 from '../../../images/using/free_rooms_3_gym.png';
import freeRooms3 from '../../../images/using/free_rooms_2.png';
import './using.css';

class Using extends Component {
    next() {
        const el = document.getElementsByClassName('slick-next');
        el[1].click();
    }

    render() {
        const settings = {
            dots: false,
            arrow: true,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div style={{backgroundColor: '#f2f3f5'}}>
                <section className="blog-wrapper blog-one pt-60 pb-50" id="using">
                    <Grid className="websitePadding">
                        <Grid item sm={12} xs={12} className="col-sm-12">
                            <Grid className="theme-heading">
                                <h2 className="yellow">Сфери застосування</h2>
                            </Grid>
                        </Grid>
                        <Slider {...settings}>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img" 
                                        style={{backgroundImage: `url(${publicEvent1})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date">
                                            <span>Каса</span>
                                        </Grid>
                                        <h3>Громадські заходи</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${publicEvent2})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-long">
                                            <span>Офіс для організації заходів</span>
                                        </Grid>
                                        <h3>Громадські заходи</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${business})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-medium">
                                            <span>Проектне бюро</span>
                                        </Grid>
                                        <h3>Будівництво, промисловість, торгівля та малий бізнес</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${construction})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-medium">
                                            <span>Офісна будівля</span>
                                        </Grid>
                                        <h3>Будівництво, промисловість, торгівля та малий бізнес</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${freeRooms1})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-long">
                                            <span>Готові робочі місця за декілька годин</span>
                                        </Grid>
                                        <h3>Приміщення вільного призначення</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${freeRooms2})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-medium">
                                            <span>Тренажерний зал</span>
                                        </Grid>
                                        <h3>Приміщення вільного призначення</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className="blog-carousel-items owl-carousel owl-theme" onClick={this.next.bind(this)}>
                                <Grid className="single-blog-box">
                                    <Grid className="blog-feature-img"
                                        style={{backgroundImage: `url(${freeRooms3})`}}></Grid>
                                    <Grid className="blog-box-content">
                                        <Grid className="post-date subusing-title-medium">
                                            <span>Вільний простір</span>
                                        </Grid>
                                        <h3>Приміщення вільного призначення</h3>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Slider>
                    </Grid>
                </section>
            </div>
        );
    }
}

export default Using;