import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import solidPanel from '../../../images/panels/solid_panel.png';
import solidPanelWithGlass from '../../../images/panels/solid_panel_with_glass.png';
import doorPanel from '../../../images/panels/door_panel.png';
import doorPanelWithGlass from '../../../images/panels/door_panel_with_glass.png';
import halfPanel from '../../../images/panels/half_panel.png';
import doubleDoorPanel from '../../../images/panels/double_door_panel.png';
import santechPanel from '../../../images/panels/santech_panel.png';
import windowPanel1 from '../../../images/panels/window_panel_1.png';
import windowPanel2 from '../../../images/panels/window_panel_2.png';
import doubleWindowPanel from '../../../images/panels/double_window_panel.png';
import doublePanelWithGlass from '../../../images/panels/double_panel_with_glass.png';
import glassWithDoubleDoors from '../../../images/panels/glass_with_double_doors.png';
import glassWithWindow from '../../../images/panels/glass_with_window.png';
import glassWithDoor from '../../../images/panels/glass_with_door.png';
import './panels.css';


class Panels extends Component {
    render() {
        return (
            <div style={{backgroundColor: '#f2f3f5'}}>
                <section className="our-panels  websitePadding pt-40 pb-40" id="panels">
                    <h2 className="text-center">Взаємозамінна панельна система*</h2>
                    <Grid id="benefits" item md={12} sm={12} xs={12} className="video-section-content">
                        <h3 className="text-center">Ваші переваги</h3>
                        <ul>
                            <li><h4>Можна пристосувати чи розширити у будь-який час</h4></li>
                            <li><h4>Просте перепланування</h4></li>
                            <li><h4>Індивідуальне планування приміщень</h4></li>
                            <li><h4>Можливі інші варіанти скління, наприклад панорамне вікно</h4></li>
                        </ul>
                    </Grid>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={3} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid style={{display: 'flex', justifyContent: 'space-evenly', gap: '5px'}}>
                                    <div>
                                        <img src={solidPanel} alt=""/>
                                    </div>
                                    <div>
                                        <img src={solidPanelWithGlass} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Глуха панель<br/>(опція - зі склінням)</h4>
                        </Grid>
                        <Grid item md={1} sm={3} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={halfPanel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Половин-<br/>часта<br/>панель</h4>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid style={{display: 'flex', justifyContent: 'space-evenly', gap: '5px'}}>
                                    <div>
                                        <img src={doorPanel} alt=""/>
                                    </div>
                                    <div>
                                        <img src={doorPanelWithGlass} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Дверна панель<br/>опція - зі склінням)</h4>
                        </Grid>
                        <Grid item md={3} sm={3} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={doubleDoorPanel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Панель з двостул-<br/>ковими дверима<br/>(опція - зі склінням)</h4>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={santechPanel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Сантехнічна панель</h4>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={4} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid style={{display: 'flex', justifyContent: 'space-evenly', gap: '5px'}}>
                                    <div>
                                        <img src={windowPanel1} alt=""/>
                                    </div>
                                    <div>
                                        <img src={windowPanel2} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Віконна панель</h4>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={doubleWindowPanel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Панель подвійна, віконна</h4>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={doublePanelWithGlass} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Подвійна панель зі склінням</h4>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={4} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={glassWithDoubleDoors} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Скління з двостулковими дверима</h4>
                        </Grid>
                        <Grid item md={4} sm={3} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={glassWithWindow} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Скління з поворотно-відкидним елементом</h4>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} className="">
                            <Grid className="single-panel">
                                <Grid>
                                    <div>
                                        <img src={glassWithDoor} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                            <h4 className="text-center">Скління з дверима</h4>
                        </Grid>
                    </Grid>
                    <p>*Різні панелі, залежно від лінійки продукту</p>
                </section>
            </div>
        )
    }
}

export default Panels;