import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import FooterCall from '../../../images/call.png';
import FooterHome from '../../../images/home.png';
import FooterLike from '../../../images/like.png';
import brandYellowTransparent from '../../../images/brand_yellow_transparent.png';
import './footer.css';

class Footer extends Component {
    render() {
        return (
            <Grid>
                <section
                    className={(this.props.websiteUrl == "/services" || this.props.websiteUrl == "/portfolio") ? "contact-section contact-sec-bg" : "contact-section"}
                    id="contact">
                    <Grid container spacing={3} className="contact-info-bg websitePadding" id="contacts">
                        <Grid item lg={4} sm={4} xs={12} className="">
                            <Grid className="single-contact-info">
                                <Grid className="contact-icon">
                                    <img src={FooterCall} alt=""/>
                                </Grid>
                                <Grid className="contact-content">
                                    <h4 className='blue'>Контакти</h4>
                                    <p>тел.: <a href="callto:+380675404477" className='contact'>+38 067 540 44 77</a></p>
                                    <p>e-mail: <a href="mailto:+380675404477" className='contact'>info@ax.ua</a></p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12} className="">
                            <Grid className="single-contact-info">
                                <Grid className="contact-icon">
                                    <img src={FooterHome} alt=""/>
                                </Grid>
                                <Grid className="contact-content">
                                    <h4 className='blue'>Адреса</h4>
                                    <p>Україна, м.Харків, пр-т Юрія Гагаріна, 187/1</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={12} className="">
                            <Grid className="single-contact-info">
                                <Grid className="contact-icon">
                                    <img src={FooterLike} alt=""/>
                                </Grid>
                                <Grid className="contact-content">
                                    <h4 className='blue'>Соцмережі</h4>
                                    <Grid className="follow-icons">
                                        <a href="javascript:"><i className="fab fa-facebook-f"></i></a>
                                        <a href="javascript:"><i className="fab fa-twitter"></i></a>
                                        <a href="javascript:"><i className="fab fa-linkedin-in"></i></a>
                                        <a href="javascript:"><i className="fab fa-instagram"></i></a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
                <footer className="footer-section pt-150 pb-30">
                    <Grid container spacing={3} className="pb-80 websitePadding">
                        <Grid item lg={6} sm={6} className="">
                            <Grid className="single-footer-widget">
                                <h3>Про компанію</h3>
                                <p>Приватна українська компанія, яка входить до групи компаній, що спеціалізуються
на будівництві, поставках будівельних матеріалів та транспортних послугах</p>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} sm={6}  className="text-right">
                            <Grid className="single-footer-widget">
                                <h3>Посилання</h3>
                                <ul>
                                    <li><a href="#about">Про компанію</a></li>
                                    <li><a href="#whatwedo">Наші послуги</a></li>
                                    <li><a href="#products">Продукція</a></li>
                                    <li><a href="#contacts">Контакти</a></li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className="footer-bootm pt-30">
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item lg={6} sm={12} className="footer-text">
                                    <h3 className='blue'>©2023 AX</h3>
                            </Grid>
                            <Grid item lg={6} sm={12} className="footer-text text-right">
                                <img src={brandYellowTransparent} alt=""/>
                            </Grid>
                        </Grid>
                    </Grid>
                </footer>
            </Grid>
        )
    }
}

export default Footer;