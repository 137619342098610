import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import HomePage from "./component/Home1/Page";
import Footer from "./component/Home1/Footer";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

let ServicePath = window.location.href;

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      siteUrl: window.location.pathname,
      uiToTopActive: '',
    }
    window.addEventListener("scroll", this.setUIToTopClass.bind(this));
  }

  handleClick() {
   this.setState({
    ...this.state,
     siteUrl : window.location.pathname
   });
  }

  setUIToTopClass() {
    if (
        document.documentElement.scrollTop > 70 ||
        document.body.scrollTop > 70
    ) {
      this.setState({
          ...this.state,
          uiToTopActive : 'active'
        });
    } else if (
        document.documentElement.scrollTop < 70 ||
        document.body.scrollTop < 70
    ) {
        this.setState({
          ...this.state,
          uiToTopActive : ''
        });
    }
  }

  render() {
    return (
        <div onClick={this.handleClick.bind(this)} className="App">
          <div className="main-wrapper">
            <Router>
              <Switch>
                <Route exact path='/' component={HomePage}/>
              </Switch>
              <Footer websiteUrl={this.state.siteUrl}/>
            </Router>
            <a href="#" id="ui-to-top" className={"ui-to-top " + this.state.uiToTopActive}><KeyboardArrowUpIcon/></a>
          </div>
        </div>
    );
  }
}
export default App;
