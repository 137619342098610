import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import Wedo1 from '../../../images/services/plan.png';
import Wedo2 from '../../../images/services/loading.png';
import Wedo3 from '../../../images/services/trasport.png';
import Wedo4 from '../../../images/services/montage.png';
import Wedo5 from '../../../images/services/construction.png';
import Wedo6 from '../../../images/services/public_event_1.png';
import Wedo7 from '../../../images/services/buy_back_people.png';
import Wedo8 from '../../../images/services/keeping.png';
import Wedo9 from '../../../images/services/rent_1.png';
import trasport from '../../../images/services/trasportH375.png';
import keeping from '../../../images/services/keepingH275.png';
import loading from '../../../images/services/loadingH275.png';
import rent1 from '../../../images/services/rent_1H375.png';
import rent2 from '../../../images/services/rent_2H275.png';
import rent3 from '../../../images/services/war_house_insideH275.png';
import backBuy from '../../../images/services/buy_back_peopleH375.png';
import backBuyWarranty from '../../../images/buy_back_sign.png';
import pictos1 from '../../../images/services/pictos1.png';
import pictos2 from '../../../images/services/pictos2.png';
import './wwd.css';


class WhatWeDo extends Component {
    render() {
        return (
            <>
                <div style={{backgroundColor: '#f2f3f5'}}>
                    <section className="what-we-do pt-60 websitePadding" id="whatwedo">
                        <Grid container spacing={3}>
                            <Grid item lg={1} xs={12}></Grid>
                            <Grid item lg={10} xs={12} className="offset-lg-1 col-12 text-center">
                                <Grid className="what-we-do-title">
                                    <h2 className="yellow">Наші послуги</h2>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo1} alt=""/>
                                    <h3><b>Розробка та візуалізація</b> Вашого проекта в <b>3D</b></h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo2} alt=""/>
                                    <h3><b>Комплексна реалізація проектів</b> від підключення мереж
        (водопостачання, каналізація, енергопостачання) до
        облаштування благоустрою прилеглої території</h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo3} alt=""/>
                                    <h3><b>Доставка</b> спеціальною вантажною технікою 7 днів на тиждень</h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo4} alt=""/>
                                    <h3><b>Надшвидкий монтаж:</b> всього 2 години на монтаж одного модуля</h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo5} alt=""/>
                                    <h3><b>Сертифіковані монтажні бригади</b></h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo6} alt=""/>
                                    <h3><b>Реалізація модульних будівель</b> з можливістю автономних систем водопостачання та енергопостачання</h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo7} alt=""/>
                                    <h3><b>Можливість зворотнього викупу</b> за найкращою ціною</h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo8} alt=""/>
                                    <h3><b>Гарантована наявність запчастин</b></h3>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="text-center">
                                <Grid className="single-we-do">
                                    <img src={Wedo9} alt=""/>
                                    <h3><b>Оренда</b> стандартних модулів</h3>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                </div>
                <div style={{backgroundColor: 'white'}}>
                    <section className="what-we-do pt-60 websitePadding" id="whatwedo_detals1">
                        <h2 className="text-center yellow">Доставка та монтаж</h2>
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                                <h3 className="text-center">Мобільність, швидкість та якість - наші приоритети</h3>
                                <ul>
                                    <li><h4>Безкоштовне зберігання на наших регіональних складах</h4></li>
                                    <li><h4>Транспортування до 8 модулів за раз</h4></li>
                                    <li><h4>Монтаж краном-маніпулятором 7 днів на тиждень</h4></li>
                                </ul>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={trasport} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content picto">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={pictos1} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={keeping} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={loading} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                    <section className="what-we-do pt-60 websitePadding" id="whatwedo_detals2">
                        <h2 className="text-center yellow">Модулі для оренди</h2>
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                                <h3 className="text-center">Ідеальне рішення для тимчасового використання</h3>
                                <ul>
                                    <li><h4>Великий парк власних модулів для оренди</h4></li>
                                    <li><h4>Широка мережа складів по Україні</h4></li>
                                    <li><h4>Меблі та комплектуючі в оренду</h4></li>
                                    <li><h4>Швидке постачання та готовнисть до експлуатації</h4></li>
                                    <li><h4>Привабливі орендні ціни</h4></li>
                                </ul>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={rent1} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content picto">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={pictos2} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={rent2} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12} className="img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div>
                                            <img src={rent3} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </section>
                    <section className="what-we-do pt-60 websitePadding" id="whatwedo_detals3">
                        <h2 className="text-center yellow">Викуп модулів</h2>
                        <Grid container spacing={3} className="websitePadding">
                            <Grid item md={6} sm={6} xs={12} className="description video-section-content">
                                <h3 className="text-center">Гарантія зворотнього викупу</h3>
                                <ul>
                                    <li><h4>Зворотній викуп модулів - незалежно від місця та часу*</h4></li>
                                    <li><h4>Просте та швидке оформлення</h4></li>
                                    <li><h4>Чесна оцінка вартості при зворотньому викупі</h4></li>
                                    <li><h4>Огляд за домовленістю на місці</h4></li>
                                    <li><h4>Швидкий демонтаж та вивіз</h4></li>
                                    <li><h4>Гарантована та швидка оплата</h4></li>
                                </ul>
                                <p>*Гарантія зворотнього викупу поширюється на модулі багаторазового використання
в Україні; обмеження за часом - не більше ніж 10 років; для інших країн
умови обговорюються окремо</p>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12} className="back-buy-img-container video-section-content">
                                <Grid className="single-block">
                                    <Grid>
                                        <div className="two-img-container">
                                            <img className="main-img" src={backBuy} alt=""/>
                                            <img className="badge-img" src={backBuyWarranty} alt=""/>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </section>
                </div>
            </>
        )
    }
}

export default WhatWeDo;