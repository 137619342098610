import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import basicLine from '../../../images/products/basic_line.png';
import classicLine from '../../../images/products/classic_line.png';
import plusLine from '../../../images/products/plus_line.png';
import './products.css';


function createData(property, value1, value2, value3) {
  return { property, value1, value2, value3 };
}

const rows = [
  createData(
    <Grid className='line-list'>
                            <ul>
                                <li>
                                    <h4>Модульні будівлі</h4>
                                </li>
                                <li>
                                    <h4>Офісні модулі</h4>
                                </li>
                                <li>
                                    <h4>Модулі для військових</h4>
                                </li>
                                <li>
                                    <h4>Сантехнічні модулі</h4>
                                </li>
                                <li>
                                    <h4>Мобільні шпиталі</h4>
                                </li>
                            </ul>
                        </Grid>, <img src={basicLine} alt=""/>, <img src={classicLine} alt=""/>, <img src={plusLine} alt=""/>),
  createData('Розміри', '20\'', '10\', 16\', 20\', 24\', 30\'', '10\', 16\', 20\', 24\''),
  createData('Внутрішня висота приміщення (мм)', '2.340', '2.340, 2.540, 2.700', '2.550'),
  createData('Зовнішня висота контейнера (мм)', '2.591', '2.591, 2.800, 2.960', '3.100'),
  createData('Варіанти теплоізоляції', 
            'Мінеральна вата, ПУ*, ПС**', 
            'Мінеральна вата, ПУ*, ПІР***, Базальтова вата', 
            'Мінеральна вата, ПУ*, ПІР***'),
];

export default function Products() {
  return (
        <section className="what-we-do section-padding websitePadding" id="products">
            <Grid container spacing={3}>
                    <Grid item lg={1} xs={12}></Grid>
                    <Grid item lg={10} xs={12} className="offset-lg-1 col-12 text-center">
                        <Grid className="what-we-do-title">
                            <h2 className="yellow">Асортимент продукції</h2>
                        </Grid>
                    </Grid>
            </Grid>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" className='big-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Доступні лінійки</TableCell>
                            <TableCell align="center">BASIC Line<br/>Функціональний офісний модуль</TableCell>
                            <TableCell align="center">CLASSIC Line<br/>Універсальне рішення</TableCell>
                            <TableCell align="center">PLUS Line<br/>Інноваційна модульна система</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.property}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.property}</TableCell>
                                <TableCell align="center">{row.value1}</TableCell>
                                <TableCell align="center">{row.value2}</TableCell>
                                <TableCell align="center">{row.value3}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell class='remark-row' colSpan={4}>
                                <h4>Розміри стандартного модуля 20 футів зовнішні - 6.055 х 2.435 мм (Д х Ш) та внутрішні - 5.860 х 2.240 мм (Д х Ш)</h4>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table aria-label="simple table" className='small-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Лінійка 1</TableCell>
                            <TableCell align="center">BASIC Line<br/>Функціональний офісний модуль</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        key={rows[0].property}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[0].property}</TableCell>
                            <TableCell align="center">{rows[0].value1}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[1].property}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[1].property}</TableCell>
                            <TableCell align="center">{rows[1].value1}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[2].property}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[2].property}</TableCell>
                            <TableCell align="center">{rows[2].value1}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[3].property}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[3].property}</TableCell>
                            <TableCell align="center">{rows[3].value1}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[4].property}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[4].property}</TableCell>
                            <TableCell align="center">{rows[4].value1}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table aria-label="simple table" className='small-table' style={{backgroundColor: '#f2f3f5'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Лінійка 2</TableCell>
                            <TableCell align="center">CLASSIC Line<br/>Універсальне рішення</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        key={rows[0].value2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell align="center">{rows[0].value2}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[1].value2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[1].property}</TableCell>
                            <TableCell align="center">{rows[1].value2}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[2].value2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[2].property}</TableCell>
                            <TableCell align="center">{rows[2].value2}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[3].value2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[3].property}</TableCell>
                            <TableCell align="center">{rows[3].value2}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[4].value2}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[4].property}</TableCell>
                            <TableCell align="center">{rows[4].value2}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table aria-label="simple table" className='small-table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Лінійка 3</TableCell>
                            <TableCell align="center">PLUS Line<br/>Інноваційна модульна система</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                        key={rows[0].value3}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row"></TableCell>
                            <TableCell align="center">{rows[0].value3}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[1].value3}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[1].property}</TableCell>
                            <TableCell align="center">{rows[1].value3}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[2].value3}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[2].property}</TableCell>
                            <TableCell align="center">{rows[2].value3}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[3].value3}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[3].property}</TableCell>
                            <TableCell align="center">{rows[3].value3}</TableCell>
                        </TableRow>
                        <TableRow
                        key={rows[4].value3}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{rows[4].property}</TableCell>
                            <TableCell align="center">{rows[4].value3}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <p>*ПУ - Polyurethan, **ПС - Полістірол, ***ПІР - поліізоцианурат</p>
        </section>
  );
}
