import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import nspa from '../../../images/list/nspa.png';
import greenTech from '../../../images/list/green_tech.png';
import '../../../../node_modules/react-modal-video/scss/modal-video.scss';
import './list.css';

class List extends Component {
    render() {
        return (
            <section className="video-section-wrap d-flex align-items-center" 
                style={{marginTop: '20px'}}>
                <Grid container spacing={3} className="websitePadding">
                    <Grid item md={4} sm={12} >
                        <Grid className="video-bg bg-cover" 
                            style={{}}>
                                <div style={{textAlign: 'center'}}>
                                    <img class="video-bg-image" src={nspa} alt="" width={'50%'}/>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <img class="video-bg-image" src={greenTech} alt="" width={'50%'}/>
                                </div>
                        </Grid>
                    </Grid>
                    <Grid item md={1}></Grid>
                    <Grid item md={7} sm={12} className="offset-lg-1">
                        <Grid className="video-section-content production">
                            <div class="high-header">
                                <h2>Виробництво</h2>
                            </div>
                            <h4 className="yellow subtitle">Зроблено у Європі відповідно до стандартів охорони навколишнього
                            середовища та відповідає стандартам та вимогам забезпечення військової
                            інфраструктури для потреб підрозділів НАТО</h4>
                            <ul>
                                <li>
                                    <h3>Енергоефективне та екологічно чисте виробництво на
                                        сертифікованих європейських заводах</h3>
                                </li>
                                <li>
                                    <h3>Високоякісна теплоізоляція - низьке енергоспоживання</h3>
                                </li>
                                <li>
                                    <h3>Довговічні модулі - повторне використання замість виготовлення
                                        нового виробу</h3>
                                </li>
                                <li>
                                    <h3>Безперервний контроль якості та екологічний аудит на заводах</h3>
                                </li>
                                <li>
                                    <h3>Наші модульні системи використовуються силовими службами в
                                        різних умовах, вони адаптовані до поточних потреб і виконуваних
                                        завдань підрозділами збройних сил країн-учасниць НАТО</h3>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        )
    }
}

export default List;