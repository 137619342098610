import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import abb from '../../../images/companies/abb.png';
import bmw from '../../../images/companies/bmw.png';
import boels from '../../../images/companies/boels.png';
import bosch from '../../../images/companies/bosch.png';
import bouygues from '../../../images/companies/bouygues.png';
import coca_cola from '../../../images/companies/coca_cola.png';
import colas from '../../../images/companies/colas.png';
import danielli from '../../../images/companies/danielli.png';
import enbw from '../../../images/companies/enbw.png';
import enel from '../../../images/companies/enel.png';
import henkel from '../../../images/companies/henkel.png';
import hkl from '../../../images/companies/hkl.png';
import ikea from '../../../images/companies/ikea.png';
import implenia from '../../../images/companies/implenia.png';
import lafarge from '../../../images/companies/lafarge.png';
import man from '../../../images/companies/man.png';
import martini from '../../../images/companies/martini.png';
import medecins from '../../../images/companies/medecins.png';
import mersedes from '../../../images/companies/mersedes.png';
import metro from '../../../images/companies/metro.png';
import ncc from '../../../images/companies/ncc.png';
import omv from '../../../images/companies/omv.png';
import pirelli from '../../../images/companies/pirelli.png';
import porr from '../../../images/companies/porr.png';
import ramirent from '../../../images/companies/ramirent.png';
import reckit from '../../../images/companies/reckit.png';
import red_bull from '../../../images/companies/red_bull.png';
import siemens from '../../../images/companies/siemens.png';
import techship from '../../../images/companies/techship.png';
import strabag from '../../../images/companies/strabag.png';
import total from '../../../images/companies/total.png';
import valeo from '../../../images/companies/valeo.png';
import vinci from '../../../images/companies/vinci.png';
import voestalpan from '../../../images/companies/voestalpan.png';
import wienerberger from '../../../images/companies/wienerberger.png';
import './companies.css';


class Companies extends Component {
    render() {
        return (
                <section className="our-companies  websitePadding pt-40 pb-40" id="companies">
                    <h2 className="text-center">Компанії, які вже використовують модулі</h2>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={abb} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={bmw} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={boels} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={bosch} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={bouygues} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={coca_cola} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={colas} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={danielli} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={enbw} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={enel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={henkel} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={hkl} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={ikea} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={implenia} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={lafarge} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={man} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={martini} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={medecins} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={mersedes} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={metro} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={ncc} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={omv} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={pirelli} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={porr} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3} className="websitePadding">
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={ramirent} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={reckit} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={red_bull} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={siemens} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={techship} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={strabag} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={total} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={valeo} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={vinci} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={voestalpan} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={wienerberger} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={3} xs={6} className="company-container">
                            <Grid className="single-company">
                                <Grid>
                                    <div>
                                        <img src={abb} alt=""/>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
        )
    }
}

export default Companies;