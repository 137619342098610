import React, {Component} from "react";
import {Link, BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import Hidden from "@material-ui/core/Hidden";

const menus = [
    {
        id: 1,
        title: 'Головна',
        link: '/',
    },
    {
        id: 2,
        title: 'Про компанію',
        link: '#about'
    },
    {
        id: 4,
        title: 'Наші послуги',
        link: '#whatwedo'
    },
    {
        id: 6,
        title: 'Продукція',
        link: '#products'
    },
    {
        id: 7,
        title: 'Контакти',
        link: '#contacts'
    },
];

class Nav extends Component {

    state = {
        active_menu: 0,
        menu_show: false
    };

    submenuHandler = id => () => {
        this.setState({
            active_menu: id === this.state.active_menu ? 0 : id,
            menu_show: !this.state.menu_show
        })
    };

    mobileMenuHandler = () => {
        this.setState({
            menu_show: !this.state.menu_show
        })
    }

    render(props) {
        var NavURL = window.location.pathname;
        const {active_menu, menu_show} = this.state;
        return (
            <div>
                <div className="mobile-menu-item">
                    <i id="mobile_menu_icon" className="fas fa-align-justify" onClick={this.mobileMenuHandler}></i>
                    <Hidden lgUp>
                        <Collapse in={menu_show} timeout="auto" unmountOnExit>
                            <nav className={NavURL ? '' : ''}>
                                <ul className="menu-items">
                                    {menus.map(item => (
                                        <li key={item.id}>
                                            <a onClick={this.submenuHandler(item.id)} href={item.link}>{item.title}
                                                {item.submenu ? <i className={`far fa-${active_menu === item.id ? 'minus' : 'plus' }-square submenu-icon`}></i> : null}
                                            </a>

                                            <Collapse in={active_menu === item.id} timeout="auto" unmountOnExit>
                                                {item.submenu ?
                                                    <ul className="submenu">
                                                        {item.submenu.map(submenu => (
                                                            <li key={submenu.id}><a href={submenu.link}>{submenu.title}</a></li>
                                                        ))}
                                                    </ul> : null}
                                            </Collapse>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </Collapse>
                    </Hidden>
                </div>
                <Hidden mdDown>
                    <nav id="responsive-menu" className={NavURL ? 'menu-style-one menu-two' : 'menu-style-one'}>
                        <ul className="menu-items">
                            <li><a href="#about">Про компанію</a></li>
                            <li><a href="#whatwedo">Наші послуги</a></li>
                            <li><a href="#products">Продукція</a></li>
                            <li><a href="#contacts">Контакти</a></li>
                        </ul>
                    </nav>
                </Hidden>
            </div>
        )
    }
}

export default Nav;